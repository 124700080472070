import { notify } from '@sobrus-com/sobrus-design-system-v2';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { BASE_URL } from '@/env';

import { exactOnLogout, objectToString } from '@/helpers/functions';
import i18n from '@/i18n/i18next';

const AXIOS = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
  withCredentials: true,
  headers: {
    Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
  },
  // timeout: 5000,
});

AXIOS.interceptors.request.use(async (config: any) => {
  const token = Cookies.get('__csrf_token_');
  if (token) {
    if (config.method !== 'get') config.headers['X-CSRF-Token'] = token;
  }

  return config;
});

AXIOS.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error?.code === 'ERR_NETWORK' || (error instanceof AxiosError && error.code === AxiosError.ERR_NETWORK)) {
      throw new AxiosError('Network Error', AxiosError.ERR_NETWORK, error.config, error.request, error.response);
    }
    if (error?.response?.status === 401 && window.location.pathname !== '/' && window.location.pathname !== '/auth/login') {
      exactOnLogout();
      return;
    } else if (error?.message === 'canceled' || error.code === 'ERR_CANCELED') {
      return;
    } else if (error?.response?.status === 404) {
      throw error;
    } else if (error?.response?.status === 403) {
      notify({
        type: 'danger',
        msg: i18n.t('Access Denied!'),
        delay: 5000,
      });
    } else {
      let message = '';
      if (error?.response?.data?.errors && typeof error?.response?.data?.errors === 'string') {
        message = error?.response?.data?.errors;
      } else if (error?.response?.data?.errors && typeof error?.response?.data?.errors === 'object') {
        message = objectToString(error?.response?.data?.errors);
      } else if (error?.response?.errors && typeof error?.response?.errors === 'string') {
        message = error?.response?.errors;
      } else if (error?.response?.errors && typeof error?.response?.errors === 'object') {
        message = objectToString(error?.response?.errors);
      } else if (error?.response?.data?.message && typeof error?.response?.data?.message === 'string') {
        message = error?.response?.data?.message;
      } else if (error?.response?.data?.message && typeof error?.response?.data?.message === 'object') {
        message = objectToString(error?.response?.data?.message);
      }
      notify({
        type: 'danger',
        msg: message,
        delay: 5000,
      });
      throw message;
    }
  },
);

export default AXIOS;
