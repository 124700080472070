import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface TokenState<T> {
  user: T;
  loading: boolean;
}

const initialState: Readonly<TokenState<any>> = {
  user: {},
  loading: true,
};

const token = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<object>) => {
      state.user = action.payload;
      state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setToken, setLoading } = token.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default token.reducer;
