// Function to inject the GTM script dynamically in production
export const addGoogleTagManager = () => {
  const script = document.createElement('script');
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-5QT7VXS4');
  `;
  document.head.appendChild(script);
};

// Conditionally add noscript and GTM in production
export const NoScript = () => {
  if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
    return (
      <noscript>
        <iframe
          height='0'
          src='https://www.googletagmanager.com/ns.html?id=GTM-5QT7VXS4'
          style={{ display: 'none', visibility: 'hidden' }}
          title='gtm'
          width='0'
        ></iframe>
      </noscript>
    );
  }
  return null;
};
