import { type NotificationsData } from '@/components/Notifcations/useNotifications';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface NotificationsState {
  notifications: NotificationsData[];
  notificationLoading: boolean;
  notificationsLength: number;
  error: string;
}

const initialState: Readonly<NotificationsState> = {
  notifications: [] as NotificationsData[],
  notificationLoading: true,
  notificationsLength: 0,
  error: '',
};

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<NotificationsData[]>) => {
      state.notifications = action.payload;
    },
    setNotificationsLength: (state, action: PayloadAction<number>) => {
      state.notificationsLength = action.payload;
    },
    setNotificationsLoading: (state, action: PayloadAction<boolean>) => {
      state.notificationLoading = action.payload;
    },
  },
});

export const { setNotifications, setNotificationsLoading, setNotificationsLength } = notifications.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default notifications.reducer;
