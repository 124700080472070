import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface OffersState<T> {
  offers: {
    [key: string]: { data: T; loading: boolean; error: string; loadingNewData: boolean };
  };
}

const initialState: OffersState<any> = {
  offers: {},
};

const offersData = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    createOfferStorageName: (state, action: PayloadAction<string>) => {
      state.offers = {
        ...state.offers,
        [action.payload]: { data: [], loading: true, loadingNewData: false, error: '' },
      };
    },
    loadNewOffer: (state, action: PayloadAction<string>) => {
      state.offers[action.payload].loadingNewData = true;
    },
    setOffers: (state, action: PayloadAction<any>) => {
      const [key] = Object.keys(action.payload);
      const [value] = Object.values(action.payload);
      state.offers[key].loading = false;
      state.offers[key].data = value;
    },

    setOffersLoading: (state, action: PayloadAction<string>) => {
      state.offers[action.payload].loadingNewData = false;
    },
  },
});

export const { setOffers, setOffersLoading, createOfferStorageName, loadNewOffer } = offersData.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default offersData.reducer;
