import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface DataTypes<T> {
  [key: string]: { data: T; loading: boolean; error: string; loadingNewData: boolean };
}

interface DataState<T> {
  posts: DataTypes<T>;
}

const initialState: Readonly<DataState<any>> = {
  posts: {},
};

const postsData = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    createpoststorageName: (state, action: PayloadAction<string>) => {
      state.posts = {
        ...state.posts,
        [action.payload]: { data: [], loading: true, loadingNewData: false, error: '' },
      };
    },
    loadNewposts: (state, action: PayloadAction<string>) => {
      state.posts[action.payload].loadingNewData = true;
    },
    setposts: (state, action: PayloadAction<any>) => {
      const [key] = Object.keys(action.payload);
      const [value] = Object.values(action.payload);
      state.posts[key].loading = false;
      state.posts[key].data = value;
    },

    setpostsLoading: (state, action: PayloadAction<string>) => {
      state.posts[action.payload].loadingNewData = false;
    },
  },
});

export const { setposts, setpostsLoading, createpoststorageName, loadNewposts } = postsData.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default postsData.reducer;
